/* eslint-disable no-undef */
import {
	getMapsHelper
} from './util/mapsHelper';

export const maps = (function () {
	let my = {},
		multiMap = document.getElementById('location_map'),
		mapData = document.querySelectorAll('.location_item'),
		initialized = false;

	function _startMultiMap() {
		const mapHelper = getMapsHelper();

		mapHelper.ready()
			.then(() => {
				const theMap = mapHelper.createMap({
					element: multiMap,
					locationElementSelector: '.location_item',
					useRichmarker: true,
					markerReducer: el => {
						return {
							lat: el.getAttribute('lat'),
							lng: el.getAttribute('lng'),
							content: `
                        <a class="map_pin" href="${el.getAttribute('link')}" target="${el.getAttribute('target')}">
                            <svg xmlns="http://www.w3.org/2000/svg" width="43.8" height="65.65" viewBox="0 0 43.8 65.65"><g><path d="M21.9,0A21.89,21.89,0,0,0,3.54,33.83L21.9,65.65,40.27,33.83A21.91,21.91,0,0,0,21.9,0"/></g></svg>
                            <div><span>${el.getAttribute('index')}</span></div>
                        </a>
                        `
						};
					}
				});

				google.maps.event.addListenerOnce(theMap, 'idle', () => {
					if (mapData.length < 2) {
						theMap.setZoom(15);
					}
					if (document.getElementById('facility_nearby')) {
						if(window.screen.width > 950){
							theMap.setZoom(8);
						} else {
							theMap.setZoom(7);
						}
					}
					[].forEach.call(mapData, i => {
						const markerData = mapHelper.getMarker(i);
						markerData.element.addEventListener('mouseenter', () => {
							i.classList.add('hover');
						});
						markerData.element.addEventListener('mouseleave', () => {
							i.classList.remove('hover');
						});
					});
				});
			});
	}

	function _assignListener(){
		if(!initialized) {
			_startMultiMap();
			initialized = true;
		}
	}

	my.init = function () {
		if (multiMap) {
			_assignListener();
		}
	};

	return my;
})();